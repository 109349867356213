// change the theme
$theme-colors: (
  "primary": rgb(3, 176, 0),
  "danger": #ff4136
);

// Import Bootstrap and its default variables
@import 'bootstrap/dist/css/bootstrap.min.css';

.btn.focus, .btn:focus {
    box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    box-shadow: none;
    background-color: rgba(0, 128, 0);
    border-color: rgba(0, 128, 0);
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}