  
.users-page {
    height: 100%;
    min-height: 530px;
    min-width: 480px;
    padding: 30px 5%;
    position: relative;

    .user-item {
        border-bottom: 1px solid #dee2e6;
        margin-top: 10px;
        .user-item-actions {
            a {
                margin-right: 25px;
            }
        }
    }

    .add-user-row {
        width:70%;
        margin-bottom: 10px;
    }

    .add-user-error{
        color:red;
        width: 90%;
    }
}
  
@media only screen and (max-width: 1023px) {
    .users-page {
        min-width: 0;
        padding: 30px 2%;

    }
}                    
