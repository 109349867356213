html {
  scroll-behavior: smooth;
  background-color: black;
}

body, #root {
  height: 100%;
  width: 100%;
}

a {
  color: #33ACE1;
  text-decoration: none;

  &:visited,
  &:hover {
    color: #33ACE1;
  }
}

.btn-primary {
  background-color: #33ACE1;
  border-color: #33ACE1;
  
  &:hover {
    background-color: #33ACE1;
    border-color: #33ACE1;
  }

  &:active {
      background-color: rgba(51, 192, 255, 1)!important;
      border-color:rgba(51, 172, 255, 1)!important;
  }

  &:focus {
    background-color: rgb(51, 172, 225);
    border-color:#33ACE1; 
  }

  &:disabled {
      background-color: rgba(51, 172, 255, 0.6);
      opacity: 0.9;
  }
}

button {
  border: 1px solid black;
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  color: white;
  cursor: pointer;
  font-family: -apple-system, BlinkMacSystemFont, 'Sofia Pro', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 1em;
  padding: 1em 2.5em;
  outline: 0;

  &:hover {
    background-color: rgba(255, 255, 255, 0.4);
  }

  &:active {
    background-color: rgba(255, 255, 255, 0.6);
  }

  &:disabled {
    cursor: initial;
  }

  &.primary {
      background-color: rgba(51, 172, 255);
      border: none;

      &:hover {
          background-color: rgba(51, 220, 255);
      }

      &:active {
          background-color: rgba(51, 172, 255);
      }

      &:disabled {
          background-color: rgba(51, 172, 255, 0.6);
          opacity: 0.9;
      }
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Sofia Pro', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-link, 
.nav-link:hover {
  color: #33ACE1;
}

h1 {
  font-size: 5em;
  font-weight: bold;
  margin: 0.4em 0;
}

h2 {
  font-size: 3.5em;
  font-weight: bold;
  margin: 0.5em 0;
}

h3 {
  font-size: 2.5em;
  font-weight: bold;
  margin: 0.25em 0;
}

h4 {
  font-size: 2em;
  font-weight: normal;
  margin: 0.75em 0;
}

h5, div {
  font-size: 1em;
  font-weight: normal;
  margin: 0;
}

h6 {
  font-size: 0.9em;
  font-weight: normal;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.accent-color {
  color: #33ACE1;
}

@font-face {
    font-family: Sofia Pro;
    font-weight: normal;
    font-style: normal;
    src: url('./fonts/Sofia\ Pro\ Bold\ Az.otf') format("opentype");
}

@font-face {
    font-family: Sofia Pro;
    font-weight: bold;
    font-style: normal;
    src: url('./fonts/Sofia\ Pro\ Semi\ Bold\ Az.otf') format("opentype");
}

@font-face {
    font-family: Sofia Pro;
    font-weight: bolder;
    font-style: normal;
    src: url('./fonts/Sofia\ Pro\ Bold\ Az.otf') format("opentype");
}

@font-face {
    font-family: Sofia Pro;
    font-weight: lighter;
    font-style: normal;
    src: url('./fonts/Sofia\ Pro\ Light\ Az.otf') format("opentype");
}

.app {
  color: #808080;
  min-width: 300px;
  position: relative;
  transition: background-color .2s ease-out;
  overflow-y: auto;
  min-height: 800px;
  height: 100%;

  .background-image {
    height: 100%;
    left: 0;
    object-fit: cover;
    opacity: 0.4;
    pointer-events: none;
    position: absolute;
    top: 0;
    transition: opacity .2s ease-out;
    width: 100%;
    z-index: -1;
  }

  .promo-text {
    display: flex;
    align-content: center;
    text-align: center;
    color: rgb(167, 52, 137);
    margin-bottom: -18px;
  }

  &.dark-mode {
    background-color: black;
    color: rgb(190, 190, 190);

    .promo-text {
      color: yellow;
    }

    .table {
      color: white;
    }

    .background-image {
        opacity: 0.7;
    }
  }

  .page-content {
    margin: -10px 2% 20px 2%;
    overflow: hidden;
  }

  .footer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-top: 46px;
    z-index: 2;

    .footer-links {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      a {
        padding: 0 15px;
        font-size: 18px;
      }
    }

    .social-icons {
      font-size: 18px;
      text-align: center;
      margin-bottom: 20px;

      a {
        font-size: 24px;
        margin-right: 10px;
      }
    }
    .footer-content {
        text-align: center;
        padding-bottom: 20px;
    }
  }
}

.lang-ru-RU,
.lang-th-TH,
.lang-bg-BG,
.lang-de-DE {
  font-family: Arial, Helvetica, sans-serif;
}

select {
  height: 2.5em;
  min-width: 200px;
}

.form-check {
  padding-top: 10px;
}

.form-check-input {
  height: 1.5em;
  width: 1.5em;
}

.form-check-input[type=radio] {
  margin-right: 10px;
  margin-bottom: 5px;
}

.form-check .form-check-input {
  margin-right: 10px;
}

.form-check-input:checked {
  background-color: #33ACE1;
  border-color: #33ACE1;
}

 .survey-table {
   margin-top: 10px;
   th {
    padding-right: 10px;
  }

  tr {
    border:1px solid #ced4da;
    &:nth-child(even) {
      background-color:#eee;
    }
  }
}

@media only screen and (max-width: 1023px) {
  .app {
    .footer {
      .footer-links {
        display: flex;
        flex-direction: column;
        margin-top: 40px;

        a {
          margin-bottom: 5px;
        }
      }
    }  
  }
}