  
.survey-page {
    height: 100%;
    min-height: 530px;
    min-width: 480px;
    padding: 30px 5%;
    position: relative;

    .survey-phone {
        width:120px;
        height: 2.5em;
    }

    .survey-error,
    .respondent-update-error {
        color:red;
    }

    .respondent-row {
        width:70%;
        margin-bottom: 10px;
    }
}
  
@media only screen and (max-width: 1023px) {
    .survey-page {
        min-width: 0;
        padding: 30px 2%;

    }
}                    
