
.privacy-mx-page {
    height: 100%;
    min-height: 530px;
    min-width: 480px;
    padding: 30px 5%;
    position: relative;


}

@media only screen and (max-width: 1023px) {
    .privacy-mx-page {
        min-width: 0;
        padding: 30px 2%;

    }
}
