.login-page {
    height: 100%;
    width: 100%;

    .login-form {
        display: flex;
        justify-content: center;
        align-content: center;
        margin-top: 150px;

        .login-parts {
            display: flex;
            flex-direction: row;

            img {
                height: 120px;
                margin-right:80px;
                margin-top:40px;
            }
        }
    }

    .login-error {
        color: red;
    }
}

@media only screen and (max-width:1023px) {
    .login-page {    
        .login-form {
            margin-top: 100px;
            .login-parts {
                flex-direction: column;

                img {
                    margin-bottom: 20px;
                    margin-right: 0;
                }
            }
        }
    }
}