.edit-survey-page {
    height: 100%;
    min-height: 530px;
    min-width: 480px;
    padding: 30px 5%;
    position: relative;

    .survey-name,
    .survey-description,
    .survey-welcome,
    .survey-gift,
    .survey-location,
    .add-question-row {
        width:90%;
    }

    .edit-survey-row {
        margin-bottom: 15px;
    }

    .edit-survey-error {
        color: red;
    }

    .edit-survey-page-tab {
        margin-top: 10px;
    }
}
  
@media only screen and (max-width: 1023px) {
    .edit-survey-page {
        min-width: 0;
    }
}                    
