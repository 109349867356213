.survey-question-edit-answer {
    .add-answer-error {
        color: red;
    }

    .remove-icon-wrapper {
        cursor: pointer;
    }

    .form-check .form-check-input,
    .form-check-input[type=radio] {
        margin-right: 0;
    }

    .form-check-label {
        margin-left: 10px;
    }
}

.survey-question-edit {
    border-top: 1px solid #dee2e6;
    padding-top: 20px;
}