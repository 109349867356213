  
.add-survey-questions-page {
    height: 100%;
    min-height: 530px;
    padding: 30px 5%;
    position: relative;

    .question-text,
    .survey-description,
    .survey-welcome,
    .survey-gift,
    .survey-location {
        width:90%;
    }

    .add-question-error {
        color: red;
    }

    .add-question-row,
    .add-option-row {
        width:70%!important; 
    }

    .survey-question-edit {
        clear: both;
        margin-top: 30px;
    }
  
}
  
@media only screen and (max-width: 1023px) {
    .add-survey-questions {
        min-width: 0;

    }
}                    
