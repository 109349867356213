

.add-survey-ambassadors {
    height: 100%;
    min-height: 530px;
    min-width: 480px;
    padding: 30px 5%;
    position: relative;

    .ambassador-state-stats {
        margin-top: 20px;
    }

    table {
        td {
            border: 1px solid silver;
            padding: 4px 10px;
                
            &.respondent-name {
                width: 150px;
            }
        }
        th {
            border: 1px solid silver;
            padding: 4px 10px;
            background-color: #ddd;
            font-weight: normal;
        }
    }

    .user-item {
        margin-bottom: 30px;
        padding-bottom: 10px;
        border-bottom: 1px solid #dee2e6;

        .user-item-actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            a {
                margin-right: 25px;
            }
        }
    }

    .add-ambassador-error {
        color: red;
    }

    .ambassador-state-stats-default-header {
        display: contents;
    }
    .ambassador-state-stats-mobile-header {
        display: none;
    }

    .ambassador-state-stats-mobile-legend {
        display: none;
    }
}
  
@media only screen and (max-width: 1023px) {
    .add-survey-ambassadors {
        min-width: 0;

        .ambassador-state-stats-default-header {
            display: none;
        }
        .ambassador-state-stats-mobile-header {
            display: contents;
        }
        .ambassador-state-stats-mobile-legend {
            display: block;
            margin-top: 20px;
        }
    }
}                    
