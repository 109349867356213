  
.landing-page {
    height: 100%;
    min-height: 530px;
    min-width: 480px;
    padding: 0 4%;
    position: relative;
    transition: background-color .2s ease-out;

    .topbar {
        display: flex;
        justify-content: center;
        padding: 32px 0 64px 0;
        z-index: 2;

        .content {
            align-items: center;
            display: flex;
            flex-grow: 1;
            justify-content: space-between;

            img {
                width: 132px;
            }
        }
    }

    .landing-menu-item {
        .nav-link {
            color: silver;
        }
    }

    .survey-item {
        padding-top: 15px;
        border-bottom: 1px solid #dee2e6;
    }

    .dropdown-item {
        padding: 0.45rem 1rem;
    }
}
  
@media only screen and (max-width: 1023px) {
    .landing-page {
        min-width: 0;
        padding: 0 5%;

    }
}                    
