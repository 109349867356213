.navbar {
    padding-right: 15px;
    font-size: 18px;
    padding-left: 15px;
}

.mr-auto {
    margin-right: auto!important;
}
.navbar-light {
    .navbar-nav {
        .nav-link,
        .nav-link:active,
        .nav-link:hover,
        .nav-link:focus {
            color: #3ba1cc;
        }

        .nav-link:last-child {
            margin-right: 0;
        }

        .nav-link {
            margin-right:30px;
        }
    }
} 

.navbar-collapse {
    .nav-link:first-child {
        margin-left: 0;
        padding-left: 0;
    }
}

@media (min-width: 1100px) {
    .navbar {
        .form-inline .form-control {
            width: 220px;
            margin-left: 4px;
        }
    }
}

.dark-mode {
    .navbar {
        &.bg-dark {
            background-color: transparent!important;
        }

        .navbar-toggler-icon {
            background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
        }
    }
}

@media only screen and (max-width: 1023px) {
    .navbar {
        .btn-outline-success {
            margin-top:10px;
        }
    }
}